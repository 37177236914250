import { Component } from '@angular/core';

@Component({
  selector: 'app-figma',
  standalone: true,
  imports: [],
  templateUrl: './figma.component.html',
  styleUrl: './figma.component.scss'
})
export class FigmaComponent {

}
