<div class="h-dvh w-full flex flex-col gap-4 justify-center items-center px-8 py-8 bg-cover bg-no-repeat bg overflow-scroll">
  <div class="flex flex-col justify-center items-center gap-8 max-w-[600px]">
    <app-logo></app-logo>
    <!--    <p class="text-body1 text-center">We’re excited to have you. Please take a moment to set up your profile.</p>-->
    <p class="text-body2 text-primary-500 text-center">We'd love to understand a bit more about your profession. This
      will help us tailor Creators Repo to be the perfect fit for you.</p>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
      @for (profession of professions(); track profession) {
        <p class="text-body2 py-3 px-6 rounded-xl  text-nowrap text-center cursor-pointer"
           [ngClass]="profession.selected ? 'ring-2 ring-primary-500': 'ring-1 ring-neutral-300'"
           (click)="onProfessionClicked(profession)">{{ profession.profession }}</p>
      }
    </div>
    <div class="flex gap-3 items-center px-6 py-3 bg-primary-50 rounded-full cursor-pointer" (click)="onContinueClicked()">
      <p class="text-body2 text-primary-500">Continue</p>
      <app-svg-icon src="assets/icons/menu-right.svg"></app-svg-icon>
    </div>

    <div class="flex gap-3 items-center px-6 py-3 ring-1 ring-neutral-300 rounded-full cursor-pointer" (click)="onSkipClicked()">
      <p class="text-body2 text-primary-500">Skip</p>
    </div>

  </div>
</div>
