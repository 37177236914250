import { Component } from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-logo',
  standalone: true,
    imports: [
        AppSvgIconComponent
    ],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss'
})
export class LogoComponent {

}
