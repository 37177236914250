<form [formGroup]="loginForm" (ngSubmit)="onSignInClicked()"
  class="flex flex-col gap-6 items-center rounded-3xl lg:shadow-1 bg-white px-12 py-8 w-dvw lg:min-w-[520px] max-w-[600px] mx-8">
  <div class="flex flex-col lg:hidden justify-center items-center gap-3">
    <app-logo></app-logo>
    <p class=" text-body2 text-center text-primary-500 max-w-96">Discover high-quality ready-to-use design and code
      assets</p>
  </div>
  <p class="text-body1 font-bold text-primary-400">Login</p>

  <div class="flex items-center w-full gap-3 px-4 py-3 border border-neutral-300 rounded-xl cursor-pointer">
    <app-svg-icon src="assets/icons/social/google.svg" [size]="24"></app-svg-icon>
    <p class="text-body2">Sign in with Google</p>
  </div>

  <div class="flex items-center w-full gap-3 px-4 py-3 border border-neutral-300 rounded-xl cursor-pointer">
    <app-svg-icon src="assets/icons/social/github.svg" [size]="24"></app-svg-icon>
    <p class="text-body2">Sign in with Github</p>
  </div>

  <div class="flex gap-4 w-full items-center">
    <span class="grow border-b border-neutral-200"></span>
    <p class="text-caption text-neutral-400">Or Sign in Email</p>
    <span class="grow border-b border-neutral-200"></span>
  </div>

  <app-outline-text-input formControlName="email" class="w-full" iconSrc="assets/icons/email.svg" label="Email" placeholder="Ex: name@company.com"  ></app-outline-text-input>
  <app-outline-text-input formControlName="password" class="w-full" iconSrc="assets/icons/lock.svg" label="Password" placeholder=""  ></app-outline-text-input>
  <p class="self-end text-body2 text-primary-500">Forgot Password?</p>
  <app-button type="submit" class="w-full" [fullWidth]="true" (click)="onSignInClicked()">Sign in</app-button>
  <div class="self-start flex items-center gap-3">
    <p class="text-body2">Not registered yet?</p>
    <p class="text-body2 text-primary-500 cursor-pointer">Create Account</p>
  </div>

</form>
