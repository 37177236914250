import {Injectable} from '@angular/core';
import {BaseApiService} from "../../base/api/base-api.service";
import {TailwindMaterialIconsRequest} from "./tailwind-material-icons/tailwind-material-icons-request";
import {URLs} from "../../base/urls";
import {TailwindMaterialIconsResponse} from "./tailwind-material-icons/tailwind-material-icons-response";
import {LogMaterialIconClickRequest} from "./log-material-icon-click/log-material-icon-click-request";

@Injectable({
  providedIn: 'root'
})
export class TailwindcssService extends BaseApiService {

  getTailwindMaterialIcons(request: TailwindMaterialIconsRequest) {
    return this.get<TailwindMaterialIconsResponse>(URLs.getTailwindMaterialIcons, request);
  }

  logMaterialIconClick(request: LogMaterialIconClickRequest) {
    return this.post(URLs.logMaterialIconClick, request);
  }
}
