import {BehaviorSubject, Observable} from "rxjs";
import {signal} from "@angular/core";

export interface BaseStateImpl<T> {
  notifyLoading(): void;
  notifySuccess(data: T): void;
  notifyError(errorMsg: string): void;
  clearState(): void;
}

export class State<T> implements BaseStateImpl<T> {
  responseSignal = signal<T | null>(null);
  private loadingSignal = signal<boolean>(false);
  private successSignal = signal<boolean>(false);
  private errorSignal = signal<string | null>(null);
  private stateSignal = signal<RequestState | null>(null);

  readonly response = this.responseSignal.asReadonly();
  readonly loading = this.loadingSignal.asReadonly();
  readonly success = this.successSignal.asReadonly();
  readonly error = this.errorSignal.asReadonly();
  readonly state = this.stateSignal.asReadonly();

  constructor() {
    this.stateSignal.set(RequestState.initial);
  }

  notifyLoading() {
    this.loadingSignal.set(true);
    this.responseSignal.set(null);
    this.successSignal.set(false);
    this.errorSignal.set(null);
    this.stateSignal.set(RequestState.loading);
  }

  notifySuccess(data: T) {
    this.loadingSignal.set(false);
    this.responseSignal.set(data);
    this.successSignal.set(true);
    this.errorSignal.set(null);
    this.stateSignal.set(RequestState.success);
  }

  notifyError(errorMsg: string) {
    this.loadingSignal.set(false);
    this.responseSignal.set(null);
    this.successSignal.set(false);
    this.errorSignal.set(errorMsg);
    this.stateSignal.set(RequestState.error);
  }

  clearState() {
    this.loadingSignal.set(false);
    this.responseSignal.set(null);
    this.successSignal.set(false);
    this.errorSignal.set(null);
    this.stateSignal.set(RequestState.initial);
  }
}

export enum RequestState {
  initial= 'initial',
  loading = 'loading',
  error = 'error',
  success = 'success'
}
