import {Component, inject, input, OnDestroy, OnInit, signal} from '@angular/core';
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {MenuItem} from "../side-bar.component";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-side-bar-menu',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NgClass
  ],
  templateUrl: './side-bar-menu.component.html',
  styleUrl: './side-bar-menu.component.scss'
})
export class SideBarMenuComponent implements OnInit, OnDestroy {

  router = inject(Router);
  activeRoute = signal(this.router.url);
  private routeSubscription!: Subscription;

  menuItem = input.required<MenuItem>();

  ngOnInit(): void {
    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.activeRoute.set(this.router.url);
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  onMenuClicked() {
    this.router.navigate([this.menuItem().route]);
  }
}
