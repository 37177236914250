import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page-inspirations',
  standalone: true,
  imports: [],
  templateUrl: './landing-page-inspirations.component.html',
  styleUrl: './landing-page-inspirations.component.scss'
})
export class LandingPageInspirationsComponent {

}
