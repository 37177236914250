<div class="flex h-screen w-full overflow-hidden">
  <app-side-bar></app-side-bar>
  <div class="flex grow flex-col content-start overflow-hidden">
    <app-top-bar></app-top-bar>
    <div
      class="grow overflow-auto scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted ">
      <router-outlet/>
    </div>
  </div>
</div>
