<div class="relative flex flex-col gap-3 items-start justify-center focus-within:text-neutral-600">
  @if (label()) {
    <p class="text-body1 text-primary-900">{{ label() }}</p>
  }

  <div class="relative flex items-center w-full text-neutral-400">
    @if(iconSrc()) {
      <app-svg-icon [size]="18" [src]="iconSrc()!" class="absolute pointer-events-none ml-3"></app-svg-icon>
    }

    <input
      class="w-full py-3 placeholder-neutral-400 text-black rounded-xl border-none outline-none focus:border-none ring-2 ring-neutral-300 focus:ring-primary-500 focus:ring-2"
      [ngClass]="iconSrc() ? 'pr-3 pl-10' : 'px-3'"
      [type]="type()"
      [placeholder]="placeholder()"
      (blur)="onTouched()"
      (input)="onValueChange($event)">
  </div>

  @if (formControl.errors && hasErrors) {
    <p class="text-caption text-error">{{ formControl.errors | humanizeFormMessages: errorMessages() }}</p>
  }
</div>
