import { Component } from '@angular/core';

@Component({
  selector: 'app-vector-drawings',
  standalone: true,
  imports: [],
  templateUrl: './vector-drawings.component.html',
  styleUrl: './vector-drawings.component.scss'
})
export class VectorDrawingsComponent {

}
