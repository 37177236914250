<nav class="h-dvh w-[100px] bg-white shadow-1 px-2 py-4 flex flex-col items-center">
  <app-svg-icon src="assets/images/branding/logo.svg" [size]="36" class="mt-4 mb-20"></app-svg-icon>
  <div class="flex flex-col gap-6">
    @for (menu of menus; track menu) {
      <app-side-bar-menu [menuItem]="menu"></app-side-bar-menu>
    }
<!--    <div class="nav-menu-item">-->
<!--      <app-svg-icon src="assets/icons/nav-items/tailwindcss.svg" [size]="28"></app-svg-icon>-->
<!--    </div>-->
<!--    <div class="nav-menu-item">-->
<!--      <app-svg-icon src="assets/icons/nav-items/figma.svg" [size]="28"></app-svg-icon>-->
<!--    </div>-->
<!--    <div class="nav-menu-item">-->
<!--      <app-svg-icon src="assets/icons/nav-items/vectors.svg" [size]="28"></app-svg-icon>-->
<!--    </div>-->
  </div>
</nav>
