export class URLs {

  // Auth
  static signUp = '/auth/v1/signUp';
  static activateAccount = '/auth/v1/confirmEmailSignUp';
  static login = '/auth/v1/signIn';
  static refreshToken: '/auth/v1/refreshToken';
  static forgotPassword = '/auth/v1/forgetPassword';
  static resetPassword = '/auth/v1/resetPassword';

  // Tailwindcss
  static getTailwindMaterialIcons = '/users/tailwind-material-icons';
  static logMaterialIconClick = '/users/tailwind-material-icons/log-icon-click';

}

