<div class="container-fluid p-6">

  <div class="flex">
    <app-outline-text-input label="" placeholder="Search..." iconSrc="assets/icons/search.svg"
                            (changeValue)="onIconSearched($event)"></app-outline-text-input>
    <div class="grow"></div>
    <div class="flex gap-4">
      @for (filter of typeFilter(); track filter) {
        <span
          class="flex justify-center items-center px-5 rounded-full text-center text-nowrap cursor-pointer select-none"
          [ngClass]="selectedFilter() == filter ? 'bg-primary-500 text-white' : 'bg-white text-primary-500 hover:bg-primary-50'"
          (click)="onFilterSelected(filter)">{{ filter }}</span>
      }
    </div>
  </div>

  @if (iconsState.loading()) {
    <div class="grid gap-8 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 mt-12">
      @for (item of shimmer(); track item) {
        <app-shimmer type="grid"></app-shimmer>
      }
    </div>
  }

  <div class="grid gap-8 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 mt-12">
    @for (icon of iconsState.response()?.items ?? []; track icon) {
      <div class="flex justify-start">
        <div class="flex flex-col justify-center items-center gap-3 cursor-pointer" (click)="onCopyClicked(icon)">
          <div class="flex items-center justify-center rounded h-36 w-36 border border-neutral-200 cursor-pointer">
            @if (isPlatformBrowser(platformId)) {
              <svg width="38" height="38" class="text-blue-500 cursor-pointer"
                   [innerHTML]="getIcon(icon.svgCode)"></svg>
            }
          </div>
          <p class="text-body2">{{ icon.iconName }}</p>
        </div>
      </div>
    }
  </div>
</div>
