import {Component, inject, OnInit} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {LogoComponent} from "../../components/logo/logo.component";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline/outline-text-input/outline-text-input.component";
import {BaseComponent} from "../../../../../core/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    LogoComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);

  loginForm!: FormGroup;

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSignInClicked() {
    // if(this.loginForm.invalid) {
    //   return;
    // }
    this.router.navigate(['/profession-setup']);
  }
}
