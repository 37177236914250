import {Component, signal} from '@angular/core';
import {LogoComponent} from "../../../auth/presentation/components/logo/logo.component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {NgClass} from "@angular/common";
import {BaseComponent} from "../../../../core/base/base-component";

@Component({
  selector: 'app-profession-setup',
  standalone: true,
  imports: [
    LogoComponent,
    AppSvgIconComponent,
    NgClass
  ],
  templateUrl: './profession-setup.component.html',
  styleUrl: './profession-setup.component.scss'
})
export class ProfessionSetupComponent extends BaseComponent {
  professions = signal([
    {
      profession: 'UI/UX Designer',
      selected: false
    },
    {
      profession: 'Developer',
      selected: false
    },
    {
      profession: 'Product Manager',
      selected: false
    },
    {
      profession: 'Business Analyst',
      selected: false
    },
    {
      profession: 'Student',
      selected: false
    },
    {
      profession: 'Graphics Designer',
      selected: false
    },
  ]);

  onProfessionClicked(profession: any) {
    profession.selected = !profession.selected;
  }

  onContinueClicked() {
    this.router.navigate(['/tailwindcss']);
  }

  onSkipClicked() {
    this.router.navigate(['/tailwindcss']);
  }
}
