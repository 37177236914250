import {ApplicationConfig} from '@angular/core';
import {provideRouter, withComponentInputBinding, withViewTransitions} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration, withNoHttpTransferCache} from '@angular/platform-browser';
import {provideAngularSvgIcon} from "angular-svg-icon";
import { provideHttpClient, withFetch } from "@angular/common/http";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), withViewTransitions()),
    provideClientHydration(withNoHttpTransferCache()),
    provideAngularSvgIcon(),
    provideHttpClient(withFetch()), provideAnimationsAsync(),
  ]
};
