import {inject, Injectable, OnDestroy, OnInit} from "@angular/core";
import {ToastService} from "../../shared/components/toast/toast.service";
import {combineLatest, Observable, skip, Subscription} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {LoaderService} from "../../shared/components/loader/loader.service";
import {State} from "./base-state";
import {filter, map} from "rxjs/operators";
import {FormControl, FormGroup} from "@angular/forms";

// Name -> AsyncResource, AsyncValue, AsyncResult, AsyncDate
type AsyncResource<T> = Observable<T> | Promise<T>;

@Injectable()
export abstract class BaseComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);
  toasterService = inject(ToastService);
  loaderService = inject(LoaderService);

  private subscriptions: Subscription[] = [];

  constructor() {
    // this.addObservers();
  }

  validateForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  isValidField(formGroup: FormGroup, field: string) {
    const valid = formGroup.get(field)?.valid ?? true;
    const touched = formGroup.get(field)?.touched ?? false;
    return !valid && !touched;
  }

  // ngOnDestroy(): void {
  //   this.subscriptions.forEach(subscription => {
  //     if (subscription && !subscription.closed) {
  //       subscription.unsubscribe();
  //     }
  //   });
  //   this.subscriptions = [];
  // }

  // abstract addObservers(): Subscription[];

  mergeQueryParams(params: {}) {
    this.router.navigate([], {queryParams: params, queryParamsHandling: "merge"});
  }

  setQueryParams(params: {}) {
    this.router.navigate([], {queryParams: params});
  }

  subscribeToUrl(
    callback: (params: any, url: string) => void
  ): Subscription {
    let isFirstEmission = true;

    return combineLatest([
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        skip(1)
      ),
      this.route.queryParamMap,
      this.route.url.pipe(
        map(segments => segments.join(''))
      )
    ]).subscribe(([navigationEnd, params, url]) => {
      callback(params, url);
    });

    // return this.route.queryParams.pipe(
    //   filter(() => {
    //     if (isFirstEmission) {
    //       isFirstEmission = false;
    //       return false;
    //     }
    //     return true;
    //   })
    // ).subscribe(params => {
    //   callback(params);
    // });
  }

  subscribeToQueryParams(callback: (params: any) => void) {
    let isFirstEmission = true;
    return this.route.queryParams.pipe(
      filter(() => {
        if (isFirstEmission) {
          isFirstEmission = false;
          return false;
        }
        return true;
      })
    ).subscribe(params => {
      callback(params);
    });
  }


  executeRequest<T>({
                      handleSuccess = false,
                      handleError = true,
                      state,
                      request,
                      onLoading,
                      onSuccess,
                      onFailed,
                      successMessage,
                      errorMessage,
                      showLoader = false
                    }: {
    handleSuccess?: boolean,
    handleError?: boolean,
    state: State<T>;
    request: Observable<T> | Promise<T>;
    onLoading?: () => void,
    onSuccess?: (response: T) => void;
    onFailed?: (error: HttpErrorResponse) => void;
    successMessage?: string;
    errorMessage?: string;
    showLoader?: boolean
  }): void {

    /**
     * Observable Handler
     */

    if(successMessage) {
      handleSuccess = true;
    }

    if (this.isObservable(request)) {
      if (onLoading != null) {
        onLoading();
      }

      if (showLoader) {
        this.loaderService.show();
      }
      state.notifyLoading();

      request?.subscribe({
        next: (res: any) => {
          if (handleSuccess) {
            this.toasterService.success(successMessage ?? res?.data?.message ?? res?.data?.data?.message ?? 'Request successful');
          }
          state.notifySuccess(res);
          if (onSuccess != null) {
            onSuccess(res);
          }
        },
        error: (err: any) => {
          if (handleError) {
            this.toasterService.error(errorMessage ?? err ?? 'Unknown error, please contact administrator.');
          }
          if(showLoader) {
            this.loaderService.hide();
          }
          state.notifyError(err);
          if (onFailed != null) {
            onFailed(err);
          }
        },
        complete: () => {
          if(showLoader) {
            this.loaderService.hide();
          }
        },
      });

    }


    /**
     * Promise Handler
     */
    if (this.isPromise(request)) {
      if (onLoading != null) {
        onLoading();
      }

      if (showLoader) {
        this.loaderService.show();
      }
      state.notifyLoading();

      request.then((value) => {
        if (handleSuccess) {
          this.toasterService.success(successMessage ?? 'Request successful');
        }

        state.notifySuccess(value);
        if (onSuccess != null) {
          onSuccess(value);
        }
      }).catch((error) => {
        if (handleError) {
          this.toasterService.error(errorMessage ?? error ?? 'Unknown error, please contact administrator.');
        }
        state.notifyError(error);
        if (onFailed != null) {
          onFailed(error);
        }
      }).finally(() => {
        if(showLoader) {
          this.loaderService.hide();
        }
      });
    }
  }

  isObservable<T>(input: any): input is Observable<T> {
    return input && typeof input.subscribe === 'function';
  }


  isPromise<T>(input: any): input is Promise<T> {
    return input && typeof input.then === 'function';
  }

}
