import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {LogoComponent} from "./components/logo/logo.component";
import {
  OutlineTextInputComponent
} from "../../../shared/inputs/outline/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {BaseComponent} from "../../../core/base/base-component";

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    RouterOutlet,
    AppSvgIconComponent,
    LogoComponent,
    OutlineTextInputComponent,
    ButtonComponent
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent {

  onSignInClicked() {

  }
}
