<div class="h-dvh w-dvw bg-cover object-left-top bg-white lg:login-bg-image px-2">
  <div class="h-full grid grid-cols-5">

    <!--Banner Content-->
    <div class="col-span-2 hidden lg:flex flex-col gap-3 justify-center items-center">
      <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-2">
          <p class="text-h4 text-primary-600 font-light max-w-96">Unleash your</p>
          <p class="text-h4 text-primary-600 font-light max-w-96">Creativity and</p>
          <p class="text-h4 text-primary-600 font-light max-w-96">Efficiency</p>
        </div>
        <p class="text-subtitle1 text-primary-500 max-w-96">Discover high-quality ready-to-use design and code
          assets that bring your ideas to life faster and more efficiently.</p>
        <div class="flex gap-5 items-center">
          <app-svg-icon src="assets/icons/nav-items/landing.svg" class="p-2 rounded-md bg-white"
                        [size]="24"></app-svg-icon>
          <app-svg-icon src="assets/icons/nav-items/tailwindcss.svg" class="p-2 rounded-md bg-white"
                        [size]="24"></app-svg-icon>
          <app-svg-icon src="assets/icons/nav-items/figma.svg" class="p-2 rounded-md bg-white"
                        [size]="24"></app-svg-icon>
          <app-svg-icon src="assets/icons/nav-items/vectors.svg" class="p-2 rounded-md bg-white"
                        [size]="24"></app-svg-icon>
        </div>
        <app-logo class="mt-44"></app-logo>
      </div>
    </div>

    <!--Login Form-->

    <div class="relative col-span-5 lg:col-span-3 flex justify-center items-center w-full">
      <router-outlet/>
    </div>


  </div>

  <div class="hidden lg:flex lg:absolute bottom-3 left-1/2 transform -translate-x-1/2 gap-3 items-center">
    <p class="text-caption text-neutral-500">© 2024 Creators Repo. All rights reserved.</p>
    <p class="text-caption text-primary-500 cursor-pointer">Terms of Use</p>
    <p class="text-caption text-black">|</p>
    <p class="text-caption text-primary-500 cursor-pointer">Privacy Policy</p>
  </div>
</div>
