import {AfterContentInit, Component, EventEmitter, inject, input, Output} from '@angular/core';
import {FormControl, NgControl, ReactiveFormsModule} from "@angular/forms";
import {MatError, MatFormField, MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {NgClass} from "@angular/common";
import {MatInput, MatInputModule} from "@angular/material/input";
import {BaseControlValueAccessor} from "../../../../core/base/base-control-value-accessor";
import {HumanizeFormMessagesPipe} from "../../humanize-form-messages.pipe";
import {AppSvgIconComponent} from "../../../components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-outline-text-input',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    MatInput,
    HumanizeFormMessagesPipe,
    MatLabel,
    MatError,
    MatFormFieldModule,
    MatInputModule,
    AppSvgIconComponent
  ],
  templateUrl: './outline-text-input.component.html',
  styleUrl: './outline-text-input.component.scss'
})
export class OutlineTextInputComponent extends BaseControlValueAccessor implements AfterContentInit {
  appearance = input<'fill' | 'outline'>('outline');
  type = input<'text' | 'email' | 'password' | 'number' | 'search' | 'tel' | 'url'>('text');
  viewType = input<'text' | 'text-area'>('text');
  iconSrc = input<string | null>();
  label = input.required<string>();
  fullWidth = input<boolean>(true);
  placeholder = input<string>('');
  errorMessages = input<{ [key: string]: string }>({});

  @Output()
  changeValue: EventEmitter<any> = new EventEmitter<any>();

  ngControl = inject(NgControl, {optional: true, self: true});

  constructor() {
    super();
    if(this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if(formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  onValueChange(event: any) {
    const target = event.target as HTMLInputElement;
    this.onChange(target.value);
    this.changeValue.emit(target.value);
  }

}
