import {Routes} from '@angular/router';
import {TailwindcssComponent} from "./features/tailwindcss/tailwindcss.component";
import {
  LandingPageInspirationsComponent
} from "./features/landing-page-inspirations/landing-page-inspirations.component";
import {FigmaComponent} from "./features/figma/figma.component";
import {VectorDrawingsComponent} from "./features/vector-drawings/vector-drawings.component";
import {AuthComponent} from "./features/auth/presentation/auth.component";
import {LoginComponent} from "./features/auth/presentation/pages/login/login.component";
import {HomeComponent} from "./features/layouts/home/home.component";
import {
  ProfessionSetupComponent
} from "./features/initial-setup/presentation/profession-setup/profession-setup.component";

export const routes: Routes = [
  {path: '', redirectTo: '/tailwindcss', pathMatch: 'full'},
  {
    path: '', component: AuthComponent, children: [
      {path: 'login', component: LoginComponent}
    ]
  },
  { path: 'profession-setup', component: ProfessionSetupComponent },
  {
    path: '', component: HomeComponent, children: [
      {path: 'landing-page-inspirations', component: LandingPageInspirationsComponent},
      {path: 'tailwindcss', component: TailwindcssComponent},
      {path: 'figma', component: FigmaComponent},
      {path: 'vectors', component: VectorDrawingsComponent},
    ]
  },
];
