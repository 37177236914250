import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {SideBarComponent} from "../side-bar/side-bar.component";
import {TopBarComponent} from "../top-bar/top-bar.component";

@Component({
  selector: 'app-home',
  standalone: true,
    imports: [
        RouterOutlet,
        SideBarComponent,
        TopBarComponent
    ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

}
