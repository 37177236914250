import { Component } from '@angular/core';
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {SideBarMenuComponent} from "./side-bar-menu/side-bar-menu.component";

@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    SideBarMenuComponent
  ],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent {
  menus: MenuItem[] = [
    {
      icon: 'assets/icons/nav-items/landing.svg',
      label: 'Landing Inspirations',
      route: '/landing-page-inspirations',
      active: false
    },
    {
      icon: 'assets/icons/nav-items/tailwindcss.svg',
      label: 'Tailwind CSS',
      route: '/tailwindcss',
      active: false
    },
    {
      icon: 'assets/icons/nav-items/figma.svg',
      label: 'Figma',
      route: '/figma',
      active: false
    },
    {
      icon: 'assets/icons/nav-items/vectors.svg',
      label: 'Vector Designs',
      route: '/vectors',
      active: false
    }
  ];
}

export interface MenuItem {
  icon: string;
  label: string;
  route: string | null;
  active?: boolean;
}
