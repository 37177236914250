<div class="group relative p-2 rounded-md cursor-pointer"
     [ngClass]="activeRoute() === menuItem().route ? 'border-2 border-primary-600' : 'border border-neutral-300'"
     (click)="onMenuClicked()">
  <app-svg-icon [src]="menuItem().icon" [size]="28"></app-svg-icon>
  <div class="fixed w-full">
    <span
      class="menu-position absolute left-14 -top-[34px] w-auto min-w-max origin-left scale-0 rounded-md bg-black p-2 text-caption font-bold text-white shadow-md transition-all duration-100 group-hover:scale-100">
      {{ menuItem().label }}
    </span>
  </div>
</div>
